<template>
  <div class="ob-ed">
    <OnboardingLayout :step="currentStep">
      <template v-slot:content-section>
        <div class="ob-ed__new ">
          <div class="ob-ed__main">
            <div class="ob-img__responsive_view">
              <img
                src="@/assets/images/onboarding/education.svg"
                alt="education"
              />
            </div>
            <h2 class="ob-ed__heading">
              {{ $t("onboarding.education.heading") }}
            </h2>
            <div class="ob-main__sec">
              <b-form @submit="onNext">
                <b-row>
                  <b-col
                    cols
                    sm="12"
                    class="ob-responsive__view ob-responsive__pills"
                  >
                    <div class="ob-ed__selection_btn">
                      <Button
                        :class="{
                          active: educations.id === oForm.educations
                        }"
                        variant="outline-primary"
                        size="default"
                        className="ob-ed__pills"
                        v-for="educations in allConstants.education.filter(
                          i => i.valid === true
                        )"
                        :key="educations.id"
                        :data-education-id="educations.id"
                        @click="onEducationClick(educations.id)"
                        >{{ educations.name }}</Button
                      >
                    </div>
                  </b-col>
                </b-row>
                <Footer
                  :invalid="false"
                  :buttonText="$t('general.next')"
                  v-if="isNewsletter"
                />
                <Footer
                  v-else
                  :invalid="false"
                  ref="footer"
                  :buttonText="$t('onboarding.get_started.button')"
                  :showIcon="false"
                />
              </b-form>
            </div>
          </div>
        </div>
      </template>
    </OnboardingLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OnboardingLayout from "../Layout/OnboardingLayout";
import SettingsMixin from "@/components/Settings/mixin";
import OnboardingMixin from "@/components/Onboarding/mixin";
import Footer from "@/components/Layout/OnboardingPageLayout/Footer.vue";

export default {
  mixins: [SettingsMixin, OnboardingMixin],
  components: { OnboardingLayout, Footer },
  props: {
    isNewsletter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCompleted: false,
      oForm: {
        educations: ""
      },
      currentStep: 0,
      showFreeText: false
    };
  },
  computed: {
    ...mapGetters(["education"])
  },
  mounted() {
    this.currentStep = this.step;
    this.initData();
  },
  methods: {
    initData() {
      const profile = this.allProfile;
      if (profile && profile.education !== null && profile.education.id > 0) {
        this.onEducationClick(profile.education.id);
      }
    },
    onEducationClick(selId) {
      this.oForm.educations = this.allConstants.education.find(
        edu => edu.id === selId
      ).id;
      // set the focus to next button for enter key to work
      this.$refs.footer.setNextFocus();
    },
    onNext(event) {
      event.preventDefault();
      let obj = {
        education: this.oForm.educations !== "" ? this.oForm.educations : null,
        onboarding: true
      };
      if (this.isNewsletter) {
        this.updateOnboarding(obj, this.form);
      } else {
        this.updateOnboarding(obj, this.oForm).then(() => {
          this.$store.dispatch("getOnboardingPendingStatus").then(() => {
            this.$store.commit("SET_WELCOME_MODAL_STATUS", true);
            this.$router.push({ name: "Dashboard" });
          });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/onboarding/_onboarding.scss";
</style>
